import React from 'react'
import Layout from "../components/Layout"
import Datenschutz from "../components/Datenschutz"
import {SEO} from "../components/Seo";
import {Helmet} from "react-helmet";

const privacyPolicy = () => {
    return (
        <Layout>
            <Helmet>
                <title>Privacy Policy | Globale Trade Services | SAP-Knowledge like an espresso (books & e-books)</title>
            </Helmet>
            <Datenschutz/>
        </Layout>
    )
}

export default privacyPolicy

export const Head = () => (
    <SEO/>
)
